import React from 'react'
import CreateDocument from '../Components/CreateDocument/CreateDocument'
import * as queryString from "query-string";
import { Helmet } from "react-helmet";

export default function createdoc(props) {
    const url_params = queryString.parse(props.location.search);

    return (
        <div>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Create Document</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <CreateDocument url_params={url_params}/>
        </div>
    )
}
