import React,{useState, useEffect} from 'react'
import styled from 'styled-components';
import {Accordion, Card, Button,Modal, Table, Row, Col} from 'react-bootstrap';
import Select from 'react-select'
import {Link} from 'gatsby';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import EdiText from 'react-editext'
import { FaPlus, FaPencilAlt, FaWindowClose, FaCheck, FaSearch, FaGripVertical, FaTrashAlt } from "react-icons/fa";
import searchImg from '../../assets/search.svg'
import { getDefaultTemplates, getDefaultCategories, getDocumentByID, updateProjectInfo, addDocOrderId} from '../../graphql/queries';
import { useSelector, useDispatch } from "react-redux";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queryString from "query-string";
import Tick from "../../assets/tickbox.svg";
import moment from 'moment'
import makeAnimated from 'react-select/animated';


export default function CreateDocumentReport(props) {
    const {consoleLog} = require('../commonFunctions.js');
    consoleLog("CreateDocumentReport props", props);

    function toggleSelectedReport(){
        consoleLog('toggleSelectedReport', '()');
        if(props.isSelected){
            props.setSelectedReports((oldSelectedReports)=>{
                return oldSelectedReports.filter(report=>(
                    report.scope_id != props.searchResult.scope_id
                ))
            });
        }
        else{
            props.setSelectedReports((oldSelectedReports)=>{
                let newSelectedReports = [ ...oldSelectedReports ];
                newSelectedReports.push(props.searchResult);
                consoleLog("addSelectedReport newSelectedReports", newSelectedReports);
                if (!props.templateName) {
                    props.setTemplateName(newSelectedReports[0].name);
                    props.setDocLocation(newSelectedReports[0].name);
                }
                return newSelectedReports;
            });
        }
    }

    return (
        <Controller style={{background: props.isSelected ? 'rgb(211, 248, 238)':'white'}}>
            { props.isSelected &&
                <span className='selected-report'>
                    <img src={Tick} alt="Selected report checkmark" />
                </span>
            }
            <img src={require(`../../assets/reports/circle-${props.searchResult.scope_type_id}.png`).default}  alt="" style={{width:'16px', height:'16px', marginLeft:'5px'}}/>
            <div className='report-scope' style={{cursor:"pointer"}} onClick={toggleSelectedReport}>
                <span><strong>{props.searchResult.scope_type_id == 1 && 'RoofScope'}{props.searchResult.scope_type_id == 4 && 'GutterScope'}{props.searchResult.scope_type_id == 11 && 'PaintScope'}{props.searchResult.scope_type_id == 6 && 'SidingScope'}{props.searchResult.scope_type_id == 7 && 'InsulationScope'}{props.searchResult.scope_type_id == 10 && 'ConcreteScope'}{props.searchResult.scope_type_id == 12 && 'RoofScopeX'}{props.searchResult.scope_type_id == 17 && 'RoofScope+'}</strong></span>
            </div>
            <div className='report-name' style={{cursor:"pointer"}} onClick={toggleSelectedReport}>
                <span>{ props.searchResult.name }</span>
            </div>
            <div className='report-date'>
                <span>{ moment(props.searchResult.date_updated).format('MMMM Do YYYY') }</span>
            </div>
            <div className='report-est'>
                <span><strong>
                    { props.searchResult.size + " " + props.searchResult.size_unit }
                </strong></span> 
                <span>
                    { ", " + props.searchResult.buildings + " Bldg" }
                </span>
            </div>
            <div className='report-pdf'>
                <span style={{color:"#F7941C", fontSize:"13px"}}><a href={props.searchResult.pdf} target="_blank">VIEW PDF</a></span>
            </div>
        </Controller>
    )
}

const Controller = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    padding: 5px 20px 0 20px;
    border-bottom: 1px solid #ccc;

    &:hover{
        color:#F7941C;
    }

    @media(max-width: 767px){
        padding-inline: 0
    }
    
    .selected-report{
        left: 0;
        padding: 5px;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }
    .selected-report img{
        width: 15px;
        margin-top: 0;
    }

    >div{
        padding: 15px;
        width: 20%;
        text-align: right;

        @media(max-width: 767px){
            width: 22%
        }
    }
    .report-scope{
        width: 20%;
        text-align: left;
    }
    .report-name{
        width: 30%;
        text-align: left;

        @media(max-width: 767px){
            width: 34%
        }
        
    }
    .report-name span{
        cursor: pointer;
    }
    .report-pdf a{
        color: #F7941C;
        font-weight: bold;
    }
`;