import React from 'react'
import {Navbar, Nav, NavDropdown, Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/prodocs-logo.png';
import Cancel from '../../assets/cross.svg'
import { FaRegWindowClose } from "react-icons/fa";
import styled from 'styled-components';
import {Link} from 'gatsby';

export default function CreateTemplateNavbar() {
    return (
        <CreateTempNavBar>
            <div className="m-0 w-100 create-doc-nav">
                <Link to="/document"> 
                    <img style={{width:'120px'}} className="ml-3" src={Logo} alt="Logo"/>
                </Link>
                <Link to="/" className="title m-0" style={{fontFamily:'Rajdhani', fontWeight: '600', textDecoration:'none', color:'#212E33', fontSize:'16px'}} ><span className="pr-2"> <img style={{width:"26px" }} src={Cancel} alt=""/> </span>EXIT</Link>
            </div>
        </CreateTempNavBar>
    )
}

const CreateTempNavBar = styled.div`
        .create-doc-nav{
            min-height:70px;
            width:100%;
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:5px 60px;
            background-color:#F7F9FA
        }

        .title {
            &:hover {
                color : #F7941C !important;
            }
        }
        .left-side{
            img{
                width:100px;
                height:auto;
            }
            .title{
                font-size:18px;
                font-weight:500;
            }

        }
`
